import React from "react";
import NavBar from "../navigationBar/NavigationBar";
import OrderTable from "./OrderTable";

export default function OrderSection() {
  return (
    <>
      <NavBar />
      <div className="h-[90vh] flex flex-row">
        <OrderTable/>
      </div>
    </>
  );
}
