import React from "react";

export default function ProductExcel(){
    return(
        <div className="w-full h-full">
        <iframe
        className="airtable-embed"
        src="https://airtable.com/embed/appp0fBcwHEOLeBBo/shr3qfYTe6Mt87LHY?viewControls=on"
        frameBorder="0"
        width="100%"
        height="100%"
        style={{ background: "transparent", border: "1px solid #ccc" }}
        title="Product Excel"
      ></iframe>
        </div>
    );
}