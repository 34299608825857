import { Card, CardHeader, CardTitle } from "../../../../@/components/ui/card";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import {
  fetchLeadData,
  updateLeadData,
} from "store/SelectedUserLeadTemplateSlice";
import { LeadDataResponse } from "services/API";
import { HiDatabase } from "react-icons/hi";
import { ScrollArea } from "../../../../@/components/ui/scroll-area";
import { MdCancel, MdEdit, MdSave } from "react-icons/md";
import { Input } from "../../../../@/components/ui/input";
import ErrorComponent from "pageComponents/chatSection/errorComponent/ErrorComponent";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../@/components/ui/select";
import { fetchAdminList } from "store/adminSlice";
import { useToast } from "../../../../@/hooks/use-toast";
import AboutCardLoadingComponent from "./AboutCardLoadingComponent";
import NoLeadMessage from "./aboutSectionComponents/NoLeadMessage";

export default function AboutCard() {
  const dispatch: AppDispatch = useDispatch();
  const { toast } = useToast();
  const userId = useSelector((state: RootState) => state.selectedUser.userId);
  const business = useSelector((state: RootState) => state.auth.business);
  console.log("iiiii",business)
  const [editMode, setEditMode] = useState<boolean[]>([]);
  const [formState, setFormState] = useState<
    Record<number, Record<string, string>>
  >({});

  const leadData = useSelector(
    (state: RootState) => state.selectedUserLeadTemplate.leadData
  );
  const status = useSelector(
    (state: RootState) => state.selectedUserLeadTemplate.status
  );

  const admins = useSelector((state: RootState) => state.admin.admins);

  // const handleAddTemplateData = () => {
  //   setIsDialogOpen(true); 
  // };

  // const handleCloseDialog = () => {
  //   setIsDialogOpen(false); 
  // };

  useEffect(() => {
    if (userId && business) {
      dispatch(fetchLeadData({ userId }));
    }
  }, [business, dispatch, userId]);

  useEffect(() => {
    if (leadData && leadData.length > 0) {
      setEditMode(new Array(leadData.length).fill(false));
      setFormState({});
    }
  }, [leadData]);

  useEffect(() => {
    if (business) {
      dispatch(fetchAdminList({ business }));
    }
  }, [business, dispatch]);

  const toggleEditMode = (index: number) => {
    setEditMode((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });

    if (!editMode[index] && leadData) {
      const currentFields = leadData[index]?.lead_data.reduce(
        (acc: Record<string, string>, field: any) => {
          Object.entries(field.fields).forEach(([key, value]) => {
            acc[key] =
              typeof value === "string" ? value : JSON.stringify(value);
          });
          return acc;
        },
        {}
      );

      setFormState((prevState) => ({
        ...prevState,
        [index]: currentFields || {},
      }));
    }
  };

  const handleInputChange = (index: number, key: string, value: string) => {
    setFormState((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        [key]: value,
      },
    }));
  };

  const handleSave = async (index: number, leadID: string) => {
    const updatedData = formState[index];

    console.log("leadsssss", leadID);
    if (!business) {
      console.error("Error: Business is not available.");
      return;
    }
    console.log("ssssse234", updatedData);
    const updatePayload = {
      business_id: business,
      lead_id: leadID,
      status: updatedData.status,
      data: updatedData,
      owner: updatedData.owner,
    };

    if (userId) {
      try {
        const response = await dispatch(
          updateLeadData({ userId, updateData: updatePayload })
        ).unwrap();
        console.log("responce", response);
        dispatch(fetchLeadData({ userId }));
        toggleEditMode(index);
        toast({
          title: "Saved!",
          description: "Your changes have been saved successfully.",
        });
      } catch (error) {
        console.error("Failed to update lead data", error);
        toast({
          title: "Oh! Something went wrong.",
          description: "Failed to update lead data.",
        });
      }
    } else {
      console.error("Error: userId is null.");
      toast({
        title: "Oh! Something went wrong.",
        description: "There was a problem with your request.",
      });
    }
  };

  const handleStatusChange = (value: string, index: number) => {
    setFormState((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        status: value,
      },
    }));
  };

  const handleAdminAssign = (value: string, index: number) => {
    console.log("value", value);
    setFormState((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        owner: value,
      },
    }));
  };

  if (status === "loading") {
    return <AboutCardLoadingComponent />;
  }

  if (status === "failed") {
    return <ErrorComponent />;
  }

  return (
    <ScrollArea className="h-[80vh]">
      <Card className="h-[78vh] ml-2 mr-2 p-1 sm:col-span-2  bg-white border-0">
        {leadData && leadData.length > 0 ? (
          leadData.map((data: LeadDataResponse, index: number) =>
            data.lead_data && data.lead_data.length > 0 ? (
              data.lead_data.map((field: any, idx: number) => (
                <Card
                  key={`${index}-${idx}`}
                  className="mb-6  bg-gray-50 shadow-sm rounded-lg border border-gray-200"
                >
                  <div className="flex justify-end pr-6 pt-4 bg-gray-100 rounded-b-lg">
                    {editMode[index] ? (
                      <>
                        <button
                          className="text-sm font-medium text-gray-600 hover:text-gray-500 transition-colors flex items-center mr-4"
                          onClick={() => toggleEditMode(index)}
                        >
                          <MdCancel className="mr-2" size={16} />
                          Cancel
                        </button>
                        <button
                          className="text-sm font-medium text-gray-600 hover:text-gray-500 transition-colors flex items-center"
                          onClick={() => handleSave(index, field.id)}
                        >
                          <MdSave className="mr-2" size={16} />
                          Save
                        </button>
                      </>
                    ) : (
                      <button
                        className="text-sm font-medium text-gray-600 hover:text-gray-500 transition-colors flex items-center"
                        onClick={() => toggleEditMode(index)}
                      >
                        <MdEdit className="mr-2" size={16} />
                        Edit
                      </button>
                    )}
                  </div>

                  <CardHeader className="pb-4  pl-6 pr-6 pt-2 border-b border-gray-300 bg-gray-100 rounded-t-lg">
                    <CardTitle className="text-sm font-bold text-gray-800 capitalize">
                      {field.name || "Unnamed Lead Template"}
                    </CardTitle>

                    <CardTitle className="text-sm text-gray-600 mt-2">
                      Lead Owner:{" "}
                      {editMode[index] ? (
                        <Select
                          onValueChange={(value) =>
                            handleAdminAssign(value, index)
                          }
                          value={formState[index]?.owner || field.owner.id}
                        >
                          <SelectTrigger className="w-full">
                            <SelectValue
                              placeholder={
                                field.owner.name || "Assign a lead owner"
                              }
                            />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {/* <SelectLabel>Admins</SelectLabel> */}
                              {admins.map((admin) => (
                                <SelectItem
                                  key={admin._id}
                                  value={admin._id}
                                  className="capitalize"
                                >
                                  {admin.name}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      ) : (
                        <span className="font-medium capitalize">
                          {field.owner.name || "Not assigned"}
                        </span>
                      )}
                    </CardTitle>

                    <CardTitle className="text-sm text-gray-600 mt-2">
                      Current Status:{" "}
                      {editMode[index] ? (
                        <Select
                          onValueChange={(value) =>
                            handleStatusChange(value, index)
                          }
                        >
                          <SelectTrigger className="w-full">
                            <SelectValue
                              placeholder={field.status || "Select Status"}
                            />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {/* <SelectLabel>Status</SelectLabel> */}
                              {field.statusList.map((status: string) => (
                                <SelectItem key={status} value={status}>
                                  {status}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      ) : (
                        <span className="font-medium capitalize">
                          {field.status || "Unknown"}
                        </span>
                      )}
                    </CardTitle>
                  </CardHeader>

                  <div className="px-6 py-4 bg-white rounded-b-lg">
                    {Object.entries(field.fields).map(
                      ([key, value]: [string, any]) => (
                        <div
                          key={key}
                          className="py-3 border-b border-gray-100"
                        >
                          <div
                            className={`flex ${
                              editMode[index]
                                ? "flex-col"
                                : "flex-row items-center justify-between"
                            } `}
                          >
                            <span className="text-sm font-medium text-gray-700">
                              {key.charAt(0).toUpperCase() + key.slice(1)}:
                            </span>

                            {editMode[index] ? (
                              <Input
                                className="mt-2"
                                placeholder={`Edit ${key}`}
                                defaultValue={
                                  formState[index]?.[key] || value || "N/A"
                                }
                                onChange={(e) =>
                                  handleInputChange(index, key, e.target.value)
                                }
                              />
                            ) : (
                              <span className="ml-4 text-sm text-gray-600 break-all whitespace-normal">
                                {formState[index]?.[key] || value || "N/A"}
                              </span>
                            )}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </Card>
              ))
            ) : (
              <div
                key={index}
                className="flex flex-col items-center justify-center h-full text-center bg-white"
              >
                <div className="mb-4 flex flex-col items-center">
                  <HiDatabase className="text-gray-300" size={50} />
                  <h3 className="text-sm font-semibold text-gray-500 mt-4">
                    No lead data available for this user.
                  </h3>
                </div>
              </div>
            )
          )
        ) : (
          <NoLeadMessage/>
        )}
      </Card>
    </ScrollArea>
  );
}
