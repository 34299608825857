import React, { useCallback, useEffect, useRef, useState } from "react";
import { cn } from "../../../@/lib/utils";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../../@/components/ui/avatar";
import { Card, CardContent, CardHeader } from "../../../@/components/ui/card";
import LogActivity from "./selectedChatComponent/logActivity/LogActivity";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { MessageItem } from "../../../services/API";
import { fetchMessageList, resetMessages } from "store/messageSlice";
import { useMedia } from "react-use";
import { MdArrowBackIos } from "react-icons/md";
import { format, isToday, isThisWeek, isSameDay } from "date-fns";
import { CircleUser } from "lucide-react";
import { Skeleton } from "../../../@/components/ui/skeleton";
import ErrorComponent from "../errorComponent/ErrorComponent";
import AIReplyHandleButton from "./selectedChatComponent/AIReplyHandleButton";
import SelectedChatLoadingComponent from "./selectedChatComponent/SelectedChatLoadingComponent";

interface CardsChatProps {
  onBackClick: () => void;
  onShowDetails: () => void;
}

function formatDateHeader(timestamp: string): string {
  const date = new Date(timestamp);

  if (isToday(date)) {
    return "Today";
  }
  if (isThisWeek(date)) {
    return format(date, "eeee");
  }
  return format(date, "dd/MM/yyyy");
}

export function CardsChat({ onBackClick, onShowDetails }: CardsChatProps) {
  // const [open, setOpen] = useState(false)
  // const [selectedUsers, setSelectedUsers] = useState<User[]>([])
  const isMobile = useMedia("(max-width: 640px)");
  const dispatch: AppDispatch = useDispatch();
  const { messages, status, currentPage, hasMore, selectedChatroomId } =
    useSelector((state: RootState) => state.message);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const latestChatroomIdRef = useRef(selectedChatroomId);
  const avaterImage = "";
  
  const userMobileNumber = useSelector(
    (state: RootState) => state.selectedUser.userMobileNumber
  );

  //const storedMobileNumber = localStorage.getItem("selectedUserMobileNumber");

  useEffect(() => {
    latestChatroomIdRef.current = selectedChatroomId;
  }, [selectedChatroomId]);

  const fetchMessages = useCallback(async (page: number) => {
    if (selectedChatroomId) {
      setIsLoadingMore(true);
      await dispatch(fetchMessageList({ limit: 10, page }));
      setIsLoadingMore(false);
      if (page === 1) {
        setInitialLoadComplete(true);
      }
    }
  }, [dispatch, selectedChatroomId]);

  useEffect(() => {
    console.log('Selected Chatroom ID changed:', selectedChatroomId);
    if (selectedChatroomId) {
      dispatch(resetMessages());
      setInitialLoadComplete(false);
      fetchMessages(1);
    }
  }, [selectedChatroomId, dispatch, fetchMessages]);

  useEffect(() => {
    console.log('Messages updated:', messages);
  }, [messages]);

  useEffect(() => {
    if (messagesContainerRef.current && initialLoadComplete) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages, initialLoadComplete]);

  useEffect(() => {
    const handleScroll = () => {
      if (messagesContainerRef.current) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { scrollTop, clientHeight, scrollHeight } =
          messagesContainerRef.current;
        if (scrollTop <= clientHeight * 0.2 && !isLoadingMore && hasMore) {
          loadMoreMessages();
        }
      }
    };

    const container = messagesContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingMore, hasMore, currentPage]);

  const loadMoreMessages = async () => {
    if (!isLoadingMore && hasMore) {
      setIsLoadingMore(true);
      const prevHeight = messagesContainerRef.current?.scrollHeight || 0;
      await dispatch(fetchMessageList({ limit: 10, page: currentPage + 1 }));
      setIsLoadingMore(false);

      if (messagesContainerRef.current) {
        const newHeight = messagesContainerRef.current.scrollHeight;
        messagesContainerRef.current.scrollTop = newHeight - prevHeight;
      }
    }
  };

  if (status === "loading" && !initialLoadComplete) {
    return <SelectedChatLoadingComponent />;
  }

  if (status === "failed") {
    return <ErrorComponent />;
  }

  let lastMessageDate: Date | null = null;
  return (
    <div className="flex flex-col">
      <Card className=" border-0 p-1">
        <CardHeader className="flex flex-row items-center justify-between p-2 mr-2">
          <div className="flex items-center space-x-2 ml-3 cursor-pointer">
            {isMobile && (
              <MdArrowBackIos
                onClick={onBackClick}
                className="text-[1.6rem] font-medium leading-none mr-2"
              >
                Back
              </MdArrowBackIos>
            )}
            <Avatar onClick={onShowDetails}>
              {avaterImage ? (
                <AvatarImage src={avaterImage} alt="Image" />
              ) : (
                <AvatarFallback>
                  <CircleUser className="h-9 w-9" />
                </AvatarFallback>
              )}
            </Avatar>

            <div onClick={onShowDetails}>
              <p className="text-sm font-medium leading-none">
                {userMobileNumber}
              </p>
              {/* <p className="text-sm text-muted-foreground">m@example.com</p> */}
            </div>
          </div>
          <AIReplyHandleButton />
        </CardHeader>
      </Card>
      {isMobile ? (
        <Card className="border-0 pt-4 h-[78vh] flex flex-col justify-end">
          <CardContent className="overflow-y-auto" ref={messagesContainerRef}>
            {isLoadingMore && (
              <>
                {Array.from({ length: 3 }).map((_, index) => (
                  <div key={index} className="space-y-2 my-2">
                    <Skeleton className="h-6 w-48 rounded-lg" />
                    <Skeleton className="h-4 w-32 rounded-lg" />
                  </div>
                ))}
              </>
            )}
            <div className=" space-y-4 ">
              {messages.map((message: MessageItem, index: number) => {
                const messageDate = new Date(message.timestamp);
                const showDateHeader =
                  !lastMessageDate || !isSameDay(lastMessageDate, messageDate);

                lastMessageDate = messageDate;

                return (
                  <div key={index}>
                    {showDateHeader && (
                      <div className="text-center text-gray-500 text-sm my-2">
                        {formatDateHeader(message.timestamp)}
                      </div>
                    )}
                    <div
                      className={cn(
                        "flex w-max max-w-[75%] flex-col gap-2 rounded-2xl px-3 py-2 text-sm whitespace-pre-wrap break-words",
                        message.sender === "AGENT"
                          ? "ml-auto bg-zinc-950 text-white"
                          : "bg-zinc-200"
                      )}
                    >
                      <div>{message.body}</div>
                      <div className="text-xs text-gray-500 text-right">
                        {new Date(message.timestamp).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
              <div>
                {/* <div className="flex justify-center my-2">
              <ActivityDetails />
            </div> */}
              </div>
            </div>
          </CardContent>
        </Card>
      ) : (
        <Card className="border-0 pt-4 h-[70vh] flex flex-col justify-end">
          <CardContent className="overflow-y-auto" ref={messagesContainerRef}>
            {isLoadingMore && (
              <>
                {Array.from({ length: 3 }).map((_, index) => (
                  <div
                    key={index}
                    className={`flex ${
                      index % 2 === 0 ? "justify-start" : "justify-end"
                    } space-y-2 my-2`}
                  >
                    <div className={`max-w-[75%]`}>
                      <Skeleton className="h-6 w-48 rounded-lg" />
                      <Skeleton className="h-4 w-32 rounded-lg" />
                      <Skeleton className="h-4 w-32 rounded-lg" />
                      <Skeleton className="h-4 w-32 rounded-lg" />
                    </div>
                  </div>
                ))}
              </>
            )}
            <div className=" space-y-4 ">
              {messages.map((message: MessageItem, index: number) => {
                const messageDate = new Date(message.timestamp);
                const showDateHeader =
                  !lastMessageDate || !isSameDay(lastMessageDate, messageDate);

                lastMessageDate = messageDate;

                return (
                  <div key={index}>
                    {showDateHeader && (
                      <div className="text-center text-gray-500 text-sm my-2">
                        {formatDateHeader(message.timestamp)}
                      </div>
                    )}
                    <div
                      className={cn(
                        "flex w-max max-w-[75%] flex-col gap-2 rounded-2xl px-3 py-2 text-sm whitespace-pre-wrap break-words",
                        message.sender === "AGENT"
                          ? "ml-auto bg-zinc-950 text-white"
                          : "bg-zinc-200"
                      )}
                    >
                      <div>{message.body}</div>
                      <div className="text-xs text-gray-500 text-right">
                        {new Date(message.timestamp).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
              <div>
                {/* <div className="flex justify-center my-2">
                <ActivityDetails />
              </div> */}
              </div>
            </div>
          </CardContent>
        </Card>
      )}
      {/* <div>
        <ActivityDetails/>
      </div> */}
      <div className="flex flex-row-reverse mr-10 mt-2 ">
        <LogActivity />
      </div>
    </div>
  );
}
