import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../../../store/store";
import { fetchChatList } from "../../../../../store/chatSlice";
import { resetMessages, setSelectedChatroom } from "store/messageSlice";
import { setSelectedUserId } from "store/SelectedChatUserSlice";
import ErrorComponent from "../../../errorComponent/ErrorComponent";
import ChatListBoadyLoadingComponent from "./ChatListBodyLoadingComponent";
import EmptyChatListComponent from "./EmptyChatListComponent";
import ChatCardComponent from "./ChatCardComponent";

interface MyCardProps {
  onChatSelect: () => void;
}

export default function MyCard({ onChatSelect }: MyCardProps) {
  const dispatch: AppDispatch = useDispatch();
  const { chats, status, currentPage, totalPages, searchTerm } = useSelector(
    (state: RootState) => state.chat
  );
  const { selectedTemplateId, selectedStatuses } = useSelector(
    (state: RootState) => state.lead
  );
  console.log("selected onessssss", selectedTemplateId, selectedStatuses);
  const token = useSelector((state: RootState) => state.auth.token);
  //const token = localStorage.getItem("token");
  //console.log("tt",token)

  const [selectedChatroomId, setSelectedChatroomId] = useState<string | null>(
    null
  );
  const selectedBotId = useSelector(
    (state: RootState) => state.auth.selectedBotId
  );
  const selectedAdminId = useSelector((state: RootState) => state.admin.selectedFilterAdminId);
console.log("selected filtr name",selectedAdminId)

  const filteredChats = useMemo(() => {
    return chats.filter(
      (chat) =>
        (chat.userMobileNumber?.toLowerCase() ?? "").includes(
          searchTerm.toLowerCase()
        ) && !chat.userMobileNumber.includes("@g.us")
    );
  }, [chats, searchTerm]);

  const listRef = useRef<HTMLDivElement>(null);

  //const botId = "66c59e078364d9bf9790ae97";
  const templateId = selectedTemplateId ?? "all";
  const label =
    selectedStatuses.length > 0 ? selectedStatuses.join(",") : "all";

  console.log("Fetching chat list with:", {
    botId: selectedBotId,
    limit: 10,
    page: currentPage,
  });

  useEffect(() => {
    if (token) {
      dispatch(
        fetchChatList({
          label: label,
          limit: 20,
          page: 1,
          templateId: templateId,
          leadOwnerId: selectedAdminId, 
        })
      );
    }
  }, [dispatch, token, templateId, label, selectedAdminId]);

  const loadMoreChats = () => {
    if (currentPage < totalPages) {
      dispatch(
        fetchChatList({
          label: label,
          limit: 20,
          page: currentPage + 1,
          templateId: templateId,
          leadOwnerId: selectedAdminId,
        })
      );
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (listRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 5) {
          loadMoreChats();
        }
      }
    };

    const scrollContainer = listRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, totalPages]);

  if (status === "loading" && currentPage === 1) {
    return <ChatListBoadyLoadingComponent />;
  }

  if (status === "failed") {
    return <ErrorComponent />;
  }

  const handleChatSelection = (
    chatroomId: string,
    userId: string,
    userMobileNumber: string
  ) => {
    console.log("my id", chatroomId);
    console.log("Selected User ID:", userId);
    console.log("selected phone number", userMobileNumber);

    setSelectedChatroomId(chatroomId);
    dispatch(resetMessages());
    dispatch(setSelectedChatroom(chatroomId));
    dispatch(setSelectedUserId({ userId, userMobileNumber, chatroomId }));
    onChatSelect();
  };

  return (
    <div ref={listRef} className="flex flex-col h-[82vh] overflow-y-auto">
      {filteredChats.length === 0 ? (
        <EmptyChatListComponent />
      ) : (
        filteredChats.map((chat, index) => (
          <ChatCardComponent
            key={index}
            chat={chat}
            selectedChatroomId={selectedChatroomId}
            onChatClick={handleChatSelection}
          />
        ))
      )}
    </div>
  );
}
