import { File, ListFilter } from "lucide-react";

import { Badge } from "../../@/components/ui/badge";
import { Button } from "../../@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../@/components/ui/table";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../@/components/ui/tabs";
//import OrderHeaderSection from "./OrderHeaderSection";
import { Pagination, PaginationContent, PaginationEllipsis, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious } from "../../@/components/ui/pagination";
import { useState } from "react";

const orders = [
  {
    id:1,
    name: "Liam Johnson",
    email:"liam@gmail.com",
    type:"Sale",
    status:"Delivered",
    date:"2023-06-28",
    amount:"$250.00"
  },
  {
    id:2,
    name: "Olivia Smith",
    email:"olivia@gmail.com",
    type:"Refund",
    status:"Declined",
    date:"2023-06-27",
    amount:"$150.00"
  },
  {
    id:3,
    name: "Noah Williams",
    email:"noah@gmail.com",
    type:"Subscription",
    status:"Delivered",
    date:"2023-06-26",
    amount:"$350.00"
  },
  {
    id:4,
    name: "Emma Brown",
    email:"emma@gmail.com",
    type:"Sale",
    status:"Confirmed",
    date:"2023-06-26",
    amount:"$450.00"
  },
  {
    id:5,
    name: "Elina Anthony",
    email:"elina@anthony.com",
    type:"Sale",
    status:"Confirmed",
    date:"2023-06-23",
    amount:"$150.00"
  },
  {
    id:6,
    name: "Eric Jayden",
    email:"ericJy@gmail.com",
    type:"Sale",
    status:"Delivered",
    date:"2023-06-23",
    amount:"$280.00"
  },
  {
    id:7,
    name: "Nihal Perera",
    email:"nihal@gmail.com",
    type:"Sale",
    status:"Delivered",
    date:"2023-06-23",
    amount:"$250.00"
  }
];

export default function OrderTable() {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalPages] = useState(1);

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className="flex min-h-screen w-full flex-col bg-muted/40">
      <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-2 sm:pr-2">
        <main className="flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3">
          <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2">
            {/* <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-4">
              <OrderHeaderSection />
            </div> */}
            <Tabs defaultValue="week">
              <div className="flex items-center">
                <TabsList>
                  <TabsTrigger value="week">Week</TabsTrigger>
                  <TabsTrigger value="month">Month</TabsTrigger>
                  <TabsTrigger value="year">Year</TabsTrigger>
                </TabsList>
                <div className="ml-auto flex items-center gap-2">
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="outline"
                        size="sm"
                        className="h-7 gap-1 text-sm"
                      >
                        <ListFilter className="h-3.5 w-3.5" />
                        <span className="sr-only sm:not-sr-only">Filter</span>
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuLabel>Filter by</DropdownMenuLabel>
                      <DropdownMenuSeparator />
                      <DropdownMenuCheckboxItem checked>
                        Fulfilled
                      </DropdownMenuCheckboxItem>
                      <DropdownMenuCheckboxItem>
                        Declined
                      </DropdownMenuCheckboxItem>
                      <DropdownMenuCheckboxItem>
                        Refunded
                      </DropdownMenuCheckboxItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                  <Button
                    size="sm"
                    variant="outline"
                    className="h-7 gap-1 text-sm"
                  >
                    <File className="h-3.5 w-3.5" />
                    <span className="sr-only sm:not-sr-only">Export</span>
                  </Button>
                </div>
              </div>
              <TabsContent value="week">
                <Card x-chunk="dashboard-05-chunk-3">
                  <CardHeader className="px-7">
                    <CardTitle>Orders</CardTitle>
                    <CardDescription>
                      Recent orders from your store.
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <Table>
                      <TableHeader>
                        <TableRow>
                        <TableHead></TableHead>
                          <TableHead>Customer</TableHead>
                          <TableHead className="hidden sm:table-cell">
                            Type
                          </TableHead>
                          <TableHead className="hidden sm:table-cell">
                            Status
                          </TableHead>
                          <TableHead className="hidden md:table-cell">
                            Date
                          </TableHead>
                          <TableHead className="text-right">Amount</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {orders.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={9} className="text-center py-4">
                              No data available
                            </TableCell>
                          </TableRow>
                        ):(
                          orders.map((order,index) => (
                            <TableRow key={order.id} className="cursor-pointer">
                              <TableCell>
                              {(currentPage - 1) * itemsPerPage + index + 1}
                            </TableCell>
                            <TableCell>
                              <div className="font-medium">{order.name}</div>
                              <div className="hidden text-sm text-muted-foreground md:inline">
                                {order.email}
                              </div>
                            </TableCell>
                            <TableCell className="hidden sm:table-cell">
                              {order.type}
                            </TableCell>
                            <TableCell className="hidden sm:table-cell">
                              <Badge className="text-xs" variant="secondary">
                                {order.status}
                              </Badge>
                            </TableCell>
                            <TableCell className="hidden md:table-cell">
                              {order.date}
                            </TableCell>
                            <TableCell className="text-right">{order.amount}</TableCell>
                          </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
                <div className="flex justify-center mt-4">
                <Pagination>
                  <PaginationContent>
                    <PaginationItem>
                      <PaginationPrevious
                        href="#"
                        onClick={(e) => {
                          if (currentPage === 1) {
                            e.preventDefault();
                          } else {
                            handlePageChange(currentPage - 1);
                          }
                        }}
                        className={`${
                          currentPage === 1
                            ? "pointer-events-none text-gray-400"
                            : ""
                        }`}
                      />
                    </PaginationItem>

                    {Array.from({ length: totalPages }, (_, index) => (
                      <PaginationItem key={index}>
                        <PaginationLink
                          href="#"
                          onClick={() => handlePageChange(index + 1)}
                          className={`${
                            currentPage === index + 1 ? "text-bold" : ""
                          }`}
                        >
                          {index + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}

                    <PaginationItem>
                      <PaginationEllipsis />
                    </PaginationItem>

                    <PaginationItem>
                      <PaginationNext
                        href="#"
                        onClick={(e) => {
                          if (currentPage === totalPages) {
                            e.preventDefault();
                          } else {
                            handlePageChange(currentPage + 1);
                          }
                        }}
                        className={`${
                          currentPage === totalPages
                            ? "pointer-events-none text-gray-400"
                            : ""
                        }`}
                      />
                    </PaginationItem>
                  </PaginationContent>
                </Pagination>
              </div>
              </TabsContent>
            </Tabs>
          </div>
        </main>
      </div>
    </div>
  );
}
