import React from "react";
import { BsCalendarEventFill } from "react-icons/bs";

export default function NoActivityComponent(){
    return(
        <div className="flex flex-col items-center justify-center h-full text-center bg-white">
            <div className="mb-4 flex flex-col items-center">
              <BsCalendarEventFill className="text-gray-300" size={40} />
              <h3 className="text-sm font-semibold text-gray-500 mt-4">
                No log activities available
              </h3>
            </div>
          </div>
    );
}