import axios, { AxiosResponse } from "axios";
//const API_URL=process.env.REACT_APP_API_KEY 
//console.log("API URL: ",API_URL);

const API_URL = 'https://233evl980e.execute-api.ap-southeast-1.amazonaws.com/staging'
//const API_URL = 'https://233evl980e.execute-api.ap-southeast-1.amazonaws.com'//production
//const API_URL = 'http://3.0.158.95:3000' //staging

//https://233evl980e.execute-api.ap-southeast-1.amazonaws.com/bot
//zappy - 668e5ae5e887a65ae9ff95d0
//macson - 66a0ac298364d9bf97908756
//test - 66c59e078364d9bf9790ae97
// axios.defaults.withCredentials = true;

// login
export interface Bot {
  _id: string;
  name: string;
}

export interface LoginResponse {
  accessToken: string;
  bots:Bot[];
  business: string;
}
export const loginApi = async (
  email: string,
  password: string
): Promise<LoginResponse> => {
  const response = await axios.post<LoginResponse>(`${API_URL}/auth/login`, {
    email,
    password,
  });
  console.log("sussceful", response);
  return response.data;
};

//get the chat list
export interface ChatItem {
  chatroomId: string;
  name: string;
  userMobileNumber:string;
  status: string;
  lastMessage: string;
  lastActivity: string;
  userId:string;
}

export interface ChatListResponse {
  chats: ChatItem[];
  totalPages: number;
  currentPage: number;
}

export const fetchChatListApi = async (
  label: string,
  limit: number,
  page: number,
  templateId: string,
  leadOwnerId: string,
  token: string
): Promise<ChatListResponse> => {
  try {
    const response = await axios.get<ChatListResponse>(
      `${API_URL}/chat?label=${label}&limit=${limit}&page=${page}&template=${templateId}&leadOwner=${leadOwnerId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Chat list fetched successfully", response);
    return response.data;
  } catch (error: any) {
    console.error("Error fetching chat list:", error.message, error.response?.data);
    throw error; 
  }
};



//get the message list
export interface MessageItem {
  messageId: string;
  sender: string;
  body: string;
  timestamp: string;
}

export interface MessageListResponse {
  messages: MessageItem[];
  totalPages: number;
  currentPage: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  totalMessagesCount: number;
}

export const fetchMessageListApi = async (
  chatroomId: string,
  limit: number,
  page: number
): Promise<MessageListResponse> => {
  const response = await axios.get<MessageListResponse>(
    `${API_URL}/message/${chatroomId}?limit=${limit}&page=${page}`
  );
  console.log("Message list fetched successfully", response);
  console.log("Message list fetched successfully", response.data.totalMessagesCount);
  return response.data;
};

// Add log activity 
export interface ActivityLogRequest {
  title: string;
  description: string;
  userId: string;
}

export interface ActivityLogResponse {
  success: boolean;
  message: string;
}

export const postActivityLogApi = async (
  title: string,
  description: string,
  user: string
): Promise<ActivityLogResponse> => {
  const response = await axios.post<ActivityLogResponse>(
    `${API_URL}/activity-log`,
    {
      title,
      description,
      user,
    }
  );
  console.log("Activity log posted successfully", response);
  return response.data;
};

// get all log activities
export interface ActivityLogItem {
  _id: string;
  title: string;
  description: string;
  timestamp: string;
}

export const fetchLogActivitiesApi = async (
  userId: string
): Promise<ActivityLogItem[]> => {
  const response = await axios.get<ActivityLogItem[]>(
    `${API_URL}/activity-log/${userId}`
  );
  console.log("Log activities fetched successfully", response.data);
  return response.data; 
};

//get the lead templates
export interface LeadFieldItem {
  id: string;
  name: string;
  required: boolean;
  type: string;
}

export interface TemplateItem {
  lead_template_id: string;
  lead_template_name: string;
  lead_template_status_list: string[];
  fields: LeadFieldItem[];
}

export interface LeadTeamplateListResponce {
  templates : TemplateItem[];
}

export const fetchLeadTemplateList = async (
  business: string
): Promise<LeadTeamplateListResponce> => {
  const response = await axios.get<LeadTeamplateListResponce>(
    `${API_URL}/business/${business}/lead_fields`
  );
  console.log("Lead template details fetched successfully",response);
  return response.data;
}

//get selected user lead details
export interface LeadField {
  id: string;
  name: string;
  required: boolean;
  type: string;
}

// export interface RequiredFieldTemplate {
//   id: string;
//   name: string;
//   bot_id: string;
//   status_list: string[];
//   fields: LeadField[];
// }

// export interface Business {
//   name: string;
//   required_fields: RequiredFieldTemplate[];
// }

export interface UserField {
  [key: string]: string | null;  
}

export interface ExistingFieldTemplate {
  id: string;
  name: string;
  status: string;
  fields: UserField;  
  owner: {};
  requiredFields: LeadField[];
}

// export interface User {
//   existing_fields: ExistingFieldTemplate[];
// }

export interface LeadDataResponse {
  mobile: string;
  lead_data: ExistingFieldTemplate[];
}

export const fetchSelectedUserLeadTemplateData = async (userId: string): Promise<LeadDataResponse[]> => {
  try {
    const response = await axios.get<LeadDataResponse[]>(`${API_URL}/user/${userId}/lead-data`);
    console.log("my lead data information",response.data)
    return response.data;
  } catch (error) {
    console.error('Error fetching lead data:', error);
    throw error;  
  }
};

//patch the selected user's lead template data
interface UpdateLeadData {
  business_id: string;
  lead_id: string;
  status: string;
  data: Record<string, string>; 
}

export const updateLeadDetails = async (userId: string, updateData: UpdateLeadData): Promise<void> => {
  try {
    const response = await axios.patch(`${API_URL}/user/${userId}/lead-data`, updateData);
    console.log('Lead details updated successfully:', response.data);
  } catch (error) {
    console.error('Error updating lead details:', error);
    throw error;  
  }
};

//get the admin list
export interface Admin {
  _id: string;
  name: string;
}

export const fetchAdminListApi = async (
  business: string,
  token: string,
): Promise<AxiosResponse<Admin[]>> => {
  try {
    const response = await axios.get<Admin[]>(
      `${API_URL}/business/${business}/admins`, 
      {
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      }
    );
    console.log("Admin list fetched successfully", response);
    return response;
  } catch (error) {
    console.error('Error fetching admin list:', error);
    throw error;
  }
};

// get the status of AI reply
export interface StopReplyResponse {
  stopReplying: boolean;
}

export const stopReplyApi = async (
  chatroomId: string, 
): Promise<AxiosResponse<StopReplyResponse>> => {
  try {
    const response = await axios.get<StopReplyResponse>(
      `${API_URL}/chat/${chatroomId}/stop-reply`
    );
    //console.log("reply action successful", response);
    return response;
  } catch (error) {
    console.error('Error stopping AI reply:', error);
    throw error;
  }
};

//update stop-reply status
export interface StopReplyResponse {
  stopReplying: boolean;
}

export const updateStopReplyApi = async (
  chatroomId: string, stopReply: boolean
): Promise<AxiosResponse<StopReplyResponse>> => {
  try {
    console.log(`Calling API at: ${API_URL}/chat/${chatroomId}/stop-reply`);
    const response = await axios.put<StopReplyResponse>(
      `${API_URL}/chat/${chatroomId}/stop-reply`, 
      { stopReply }
    );
    console.log("AI reply status updated successfully", response);
    return response;
  } catch (error) {
    console.error('Error updating AI reply status:', error);
    throw error;
  }
};
